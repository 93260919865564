import React from 'react';
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Media from "react-md/lib/Media/Media";

import "./VideoTour.scss";

class VideoTour extends React.Component {
  constructor (props) {
         super(props);
         this.loadVideo = this.loadVideo.bind(this);
     }

  componentDidMount() {

    // On mount, check to see if the API script is already loaded
    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    } else { // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  // function is defined as static because it doesn't call any instance variables
  static onPlayerStateChange(event) {
    if (event.data === window.YT.PlayerState.PLAYING ) {
      if(typeof window !== "undefined") {
        window.gtag("event", "play", {
          category: 'Videos',
          action: 'play',
          label: 'track-video-events-play',
          value: 1
        });
      }
    };

    if (event.data === window.YT.PlayerState.ENDED ) {
      if(typeof window !== "undefined") {
        window.gtag("event", "end", {
          category: 'Videos',
          action: 'end',
          label: 'track-video-events-ended',
          value: 1
        });
      }
    };
  };

  loadVideo() {
    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player`, {
      videoId: 'MNSr0wcXCfE',
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      },
    });
  };


	// For reference, here are the possible values of the PlayerState, and their associated numeric values
	// YT.PlayerState.ENDED    0
	// YT.PlayerState.PLAYING  1
	// YT.PlayerState.PAUSED   2
	// YT.PlayerState.BUFFERING 3
	// YT.PlayerState.CUED      5

  // onPlayerReady(event) {
    // event.target.playVideo();
  // };

  render() {
    return (
      <Card raised="true">
        <CardTitle title="Mindful Montessori ELC Video Tour" />
        <Media>
          <div id="youtube-player" />
        </Media>
      </Card>
    );
  }
}

export default VideoTour;
