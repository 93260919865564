import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";

import Layout from '../../layout';
import VideoTour from '../../components/VideoTour';
import WhoAreWe from '../../components/whoarewe';

const Who = () => (
  <Layout>
    <Grid stacked>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <WhoAreWe />
      </Cell>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <VideoTour />
      </Cell>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <Card>
          <CardTitle title="Montessori Education" />
          <CardText>
            <p className="md-text-justify">
              <b>What is Montessori Education?</b>
            </p>
            <p className="md-text-justify">
              Montessori education guides the child&apos;s natural urge to create his own personality
              and humanity. He builds his mind as he uses his muscles to created coordination.
              He challenges himself to solve problems as he explores the environment and has to
              discover how things work. He builds an inner road map for his own personal organization
              through the guidance of the Montessori Guide and the order of the Montessori environment.
            </p>
            <p className="md-text-justify">
              The social and emotional aspects of the child&apos;s personality develop in an environment
              where children interact with each other and choose their work according to guidelines
              that are logical and orderly. Natural limitations in the classroom help the child to
              know that movements as well as social interactions are handled with developing
              self-discipline and care that lead to responsibility.
            </p>
            <p className="md-text-justify">
              The child learns
              &nbsp;
              <b>academic</b>
              &nbsp;
              concepts of
              &nbsp;
              <b>language, mathematics, science, geography, music and art</b>
              &nbsp;
              through his work with the Montessori materials which are self-teaching.
              The result is the child being a contributing member of their home, school and community.
              Classrooms are based on the AMI Montessori pedagogy (the art, science, or profession of teaching).
            </p>
            <p className="md-text-justify">
              The children have choices in their work selection once given a lesson by the directress.
              The directress presents lessons individually and in small groups. The Montessori philosophy
              encourages us to see children as they really are, and to provide an environment which
              enables them to reach their highest potential.
            </p>
            <p className="md-text-justify">
              <b>Benefits of a Montessori Education</b>
            </p>
            <ul className="md-text-justify">
              <li>
                Mixed ages in the classroom, with older children assisting and teaching younger children,
                builds confidence, self-esteem and compassion.
              </li>
              <li>Self-correcting materials give children the opportunity to learn through their own errors.</li>
              <li>Each child learns at their own pace.</li>
              <li>
                Concrete learning involving hands-on experience, leads to abstract learning as
                the child develops and is ready for conceptualization.
              </li>
              <li>
                Child-centered environment where all materials are within their reach
                and the furniture is comfortable for them.
              </li>
              <li>
                Work for the joy of working and the sense of discovery: children delight in
                learning new tasks and are interested in the work itself.
              </li>
              <li>
                Self-discipline develops naturally: expectations are clearly stated and followed
                through by the teacher and as well as by the older children who have absorbed the classroom order.
              </li>
              <li>
                Prepared environment, where everything has a specific place, enhancing the
                child’s natural tendency for order.
              </li>
              <li>
                Children are motivated by the need to work toward self-development. Independence is gained
                by encouraging this natural drive and shows itself in the child&apos;s emotional growth.
              </li>
            </ul>
            <p className="md-text-justify">
              <b>Goals of a Montessori Education</b>
            </p>
            <ul className="md-text-justify">
              <li>
                Help children with their task of inner construction as they grow
                from childhood to maturity.
              </li>
              <li>
                Provide a prepared environment where children are free to respond
                to their natural drive to work and learn.
              </li>
              <li>Encourage the child’s inherent love of learning.</li>
              <li>Develop concentration, motivation, persistence and discipline.</li>
              <li>Encourage each child’s unique personality.</li>
              <li>
                Develop an appreciation for the world while becoming responsible
                human beings and active members of a harmonious society.
              </li>
            </ul>
            <p>
              <i>
                “Children are human beings to whom respect is due,
                superior to us by reason of their innocence and of the greater possibilities of their future.”
              </i>
            </p>
            <p><i>-- Dr. Maria Montessori</i></p>
          </CardText>
        </Card>
      </Cell>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <Card>
          <CardTitle title="Mindful / Mindfulness" />
          <CardText>
            <p className="md-text-justify">
              Mindfulness. It’s a pretty straightforward word. It suggests that the mind is fully attending to
              what’s happening, to what you’re doing, to the space you’re moving through. That might seem trivial,
              except for the annoying fact that we so often veer from the matter at hand. Our mind takes flight,
              we lose touch with our body, and pretty soon we’re engrossed in obsessive thoughts about
              something that just happened or fretting about the future. And that makes us anxious.
            </p>
            <blockquote style={{borderLeft: '2px solid #000', paddingLeft: '10px'}}>
              <h2>
                Mindfulness is the basic human ability to be fully present, aware of where we are and what we’re doing,
                and not overly reactive or overwhelmed by what’s going on around us.
              </h2>
            </blockquote>
            <p className="md-text-justify">
              Yet no matter how far we drift away, mindfulness is right there to snap us back to where we are and
              what we’re doing and feeling. If you want to know what mindfulness is, it’s best to try it for a while...
            </p>
            <p className="md-text-right md-caption">
              Source:
              <a href="https://www.mindful.org/what-is-mindfulness/" target="_blank" rel="noopener noreferrer">Mindfulness.org</a>
            </p>
          </CardText>
        </Card>
      </Cell>
    </Grid>
  </Layout>
)

export default Who
