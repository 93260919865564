import React from "react";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import "./WhoAreWe.scss";

const WhoAreWe = () => (
  <Card>
    <CardTitle title="We are an Early Learning Center" />
    <CardText>
      <p className="md-text-justify">
        For most parents, pre-school is merely a safe place where they can leave their young
        in order to free themselves up for work. For some, perhaps, it is also an opportunity
        for their child to socialize with other children.  While these are good and necessary reasons,
        we forget that young children are learning from the moment they open their eyes. They are
        forming personalities and soaking up skills under our very noses. Much of what goes into
        making the adult persona is built on foundations laid firmly in the early years.
      </p>
      <List>
        <ListItem
          leftIcon={<FontIcon>star</FontIcon>}
          primaryText="Will they be curious or resist learning?"
        />
        <ListItem
          leftIcon={<FontIcon>star</FontIcon>}
          primaryText="Will they be confident or shy?"
        />
        <ListItem
          leftIcon={<FontIcon>star</FontIcon>}
          primaryText="Will they be creative?"
        />
        <ListItem
          leftIcon={<FontIcon>star</FontIcon>}
          primaryText="Will they form a morality based on fairness or self-interest?"
        />
      </List>
      <blockquote style={{borderLeft: '2px solid #000', paddingLeft: '10px'}}>
        <h2>
          Early Learning Centers are not just pre-schools!
        </h2>
      </blockquote>
      <p className="md-text-justify">
        We provide an environment where children acquire life skills while in the care of
        well qualified and nurturing teachers.
      </p>
    </CardText>
  </Card>
)

export default WhoAreWe;
